<app-output
  [status]="this.status"
  [error]="this.error"
  [outputCollapsed]="outputCollapsed"
  [output]="this.output"
  #outputComponent
>
  <!--[downloadProgress]="this.loaded"
  (abortExecution)="abortTriggered()"
  (abortExecutionFromCreate)="abortFromCreateTriggered()"-->
</app-output>

<div class="row mt-5">
  <div class="col-12">
    <h4>Prompt</h4>
    <div class="form-group">
      <textarea id="prompt" class="form-control" rows="4" [formControl]="promptFormControl"></textarea>
    </div>
  </div>
</div>

<div class="row mt-4">
  <div class="col-6">

    <canvas class="visualizer d-block rounded-pill mb-3" height="40px" #canvasElement></canvas>

    <button class="btn btn-primary" [disabled]="isRecording" (click)="startRecording()">
      @if(this.isRecording) {
        <div class="spinner-grow spinner-grow-sm text-light" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        Recording ...
      } @else {
        Record audio
      }
    </button>

    @if(this.isRecording) {
      <button class="btn btn-light ms-2" (click)="stopRecording()">Stop recording</button>
    }

    @if(this.recordingDuration) {
      <div class="mt-2">Duration: {{ this.recordingDuration }}</div>
    }


  </div>
  <div class="col-6">
    <h4>Preview</h4>

    @if (audioSrc) {
      <audio [src]="audioSrc" controls></audio>
    } @else {
      <p class="text-secondary">Preview will be displayed here after the recording has ended.</p>
    }

  </div>
</div>


<h4 class="mt-3">Code</h4>
<code-editor [code]="code" [readonly]="true" [height]="'700px'"></code-editor>

<div class="d-grid mt-5">
  <button class="btn btn-lg btn-primary" (click)="execute()">Execute</button>
</div>
