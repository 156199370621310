import * as i0 from '@angular/core';
import { EventEmitter, PLATFORM_ID, Component, Inject, Input, Output, ViewChild } from '@angular/core';
import * as i1 from '@angular/forms';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { isPlatformServer } from '@angular/common';
const _c0 = ["dropdownMenu"];
const _c1 = ["inputElement"];
const _c2 = [[["no-results"]]];
const _c3 = ["no-results"];
function MagienoBootstrapDropdownComponent_Conditional_1_For_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 9);
    i0.ɵɵlistener("click", function MagienoBootstrapDropdownComponent_Conditional_1_For_2_Template_button_click_0_listener() {
      const item_r3 = i0.ɵɵrestoreView(_r2).$implicit;
      const ctx_r3 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r3.selectedItemClicked(item_r3));
    });
    i0.ɵɵelement(1, "i", 10);
    i0.ɵɵelementStart(2, "span");
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const item_r3 = ctx.$implicit;
    const ctx_r3 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(ctx_r3.getLabel(item_r3));
  }
}
function MagienoBootstrapDropdownComponent_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 3);
    i0.ɵɵrepeaterCreate(1, MagienoBootstrapDropdownComponent_Conditional_1_For_2_Template, 4, 1, "button", 8, i0.ɵɵrepeaterTrackByIndex);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵrepeater(ctx_r3.selectedItems);
  }
}
function MagienoBootstrapDropdownComponent_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 11);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("ps-2", ctx_r3.selectedItem === null)("ps-3", ctx_r3.selectedItem !== null);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1("", ctx_r3.getLabel(ctx_r3.selectedItem), " ");
  }
}
function MagienoBootstrapDropdownComponent_For_8_Template(rf, ctx) {
  if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "li")(1, "button", 12);
    i0.ɵɵlistener("click", function MagienoBootstrapDropdownComponent_For_8_Template_button_click_1_listener() {
      const item_r6 = i0.ɵɵrestoreView(_r5).$implicit;
      const ctx_r3 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r3.itemSelected(item_r6));
    });
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const item_r6 = ctx.$implicit;
    const $index_r7 = ctx.$index;
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵclassProp("active", $index_r7 === ctx_r3.cursorPosition);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1("", ctx_r3.getLabel(item_r6), " ");
  }
}
function MagienoBootstrapDropdownComponent_Conditional_9_ProjectionFallback_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "button", 13);
    i0.ɵɵtext(1, "No results found.");
    i0.ɵɵelementEnd();
  }
}
function MagienoBootstrapDropdownComponent_Conditional_9_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "li");
    i0.ɵɵprojection(1, 0, null, MagienoBootstrapDropdownComponent_Conditional_9_ProjectionFallback_1_Template, 2, 0);
    i0.ɵɵelementEnd();
  }
}
function MagienoBootstrapDropdownComponent_Conditional_10_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 7);
    i0.ɵɵelement(1, "i", 14);
    i0.ɵɵelementEnd();
  }
}
class MagienoBootstrapDropdownComponent {
  platformId;
  /**
   * Named control to avoid confusion with default formControl property binding.
   */
  control = new FormControl(null);
  itemsProvider;
  /**
   * The key of the item that should be used as the label.
   *
   * By default, it assumes it's an ItemInterface and will use the "title" key.
   *
   * If you pass nothing, the value will be returned as the label.
   */
  labelKey = "title";
  getLabel(item) {
    if (!item) {
      return item;
    }
    if (this.labelKey === null) {
      return this.getValue(item);
    }
    if (item.hasOwnProperty(this.labelKey) === false) {
      throw new Error(`The item does not have the key '${this.labelKey}', we cannot use it as the label.`);
    }
    return item[this.labelKey];
  }
  /**
   * The key of the item that should be used as the value.
   *
   * By default, it assumes it's an ItemInterface and will use the "value" key and will assign it as the value in the formControl.
   *
   * If you specify null, it will direclty assign the whole object as the value in the formControl.
   */
  valueKey = "value";
  getValue(item) {
    if (!item) {
      return item;
    }
    if (this.valueKey === null) {
      return item;
    }
    if (item.hasOwnProperty(this.valueKey) === false) {
      throw new Error(`The item does not have the key '${this.valueKey}', we cannot use it as the value.`);
    }
    return item[this.valueKey];
  }
  // <editor-fold desc="Items">
  items = [];
  displayedItems = [];
  async getItems() {
    if (!this.itemsProvider) {
      const search = this.searchControl.value;
      if (search === null) {
        return this.items;
      }
      // Filter the current items and return the filtered options
      return this.items.filter(item => {
        // If we don't allow multiple selection, we should still show the item if it's already selected.
        if (!this.multiple) {
          return item.value.toLowerCase().includes(search.toLowerCase()) || item.title.toLowerCase().includes(search.toLowerCase());
        }
        // If multiple is allowed, then we should only show the item if it's not already selected.
        return this.selectedItems.includes(item) === false && (item.value.toLowerCase().includes(search.toLowerCase()) || item.title.toLowerCase().includes(search.toLowerCase()));
      });
    }
    return this.itemsProvider.getItems(this.searchControl.value ?? "");
  }
  itemSelect = new EventEmitter();
  itemDeselect = new EventEmitter();
  itemSelected(item) {
    if (this.multiple === false) {
      this.selectedItems[0] = item;
      this.control.setValue(this.getValue(item));
    } else {
      this.selectedItems.push(item);
      this.control.setValue(this.selectedItems.map(item => this.getValue(item)));
    }
    this.itemSelect.emit(item);
    this.updateDisplayedItems();
    this.searchControl.setValue('');
  }
  // </editor-fold>
  // <editor-fold desc="Dropdown">
  dropdownMenuElement;
  subscriptions = [];
  dropdown;
  dropdownOpen = false;
  click = new EventEmitter();
  clicked() {
    this.click.emit();
    this.inputElement.nativeElement.focus();
    this.updateDropdown();
  }
  // </editor-fold>
  // <editor-fold desc="Selected Items">
  get selectedItem() {
    if (this.selectedItems.length === 0) {
      return null;
    }
    return this.selectedItems[0];
  }
  selectedItems = [];
  selectedItemClicked(item) {
    // When an item already selected is clicked on, it means we want to deselect it.
    this.itemDeselect.emit(item);
    // Remove element from selected array
    this.selectedItems = this.selectedItems.filter(selectedItem => selectedItem !== item);
    this.updateDisplayedItems();
  }
  // </editor-fold>
  inputElement;
  searchControl = new FormControl("");
  focused = false;
  multiple = false;
  placeholder = "Select...";
  name = "";
  showChevronDown = true;
  // <editor-fold desc="Cursor Positions">
  _cursorPosition = -1;
  get cursorPosition() {
    return this._cursorPosition;
  }
  set cursorPosition(value) {
    this._cursorPosition = value;
  }
  moveCursorUp() {
    if (this.cursorPosition <= 0) {
      this.cursorPosition = -1;
      return;
    }
    this.cursorPosition--;
  }
  moveCursorDown() {
    if (this.cursorPosition >= this.items.length) {
      this.cursorPosition = 0;
      return;
    }
    this.cursorPosition++;
  }
  // </editor-fold>
  constructor(platformId) {
    this.platformId = platformId;
  }
  async ngOnInit() {
    this.subscriptions.push(this.searchControl.valueChanges.subscribe(async value => {
      this.cursorPosition = -1;
      this.updateDisplayedItems();
      this.updateDropdown(true);
    }));
    this.subscriptions.push(this.control.valueChanges.subscribe(async value => {
      this.syncFormControlWithSelectedItems();
      this.updateDisplayedItems();
    }));
    this.syncFormControlWithSelectedItems();
    this.updateDisplayedItems();
  }
  ngAfterViewInit() {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    // @ts-ignore
    this.dropdown = new bootstrap.Dropdown(this.dropdownMenuElement.nativeElement);
  }
  keyUp(event) {
    if (event.key === "ArrowUp") {
      this.moveCursorUp();
    } else if (event.key === "ArrowDown") {
      this.moveCursorDown();
    } else if (event.key === "Backspace") {
      if (this.searchControl.value === "" && this.selectedItems.length > 0) {
        this.selectedItemClicked(this.selectedItems[this.selectedItems.length - 1]);
      }
    } else if (event.key === "Enter") {
      this.itemSelected(this.items[this.cursorPosition]);
    }
  }
  syncFormControlWithSelectedItems() {
    if (this.multiple) {
      if (!Array.isArray(this.control.value)) {
        return;
      }
      this.selectedItems = this.items.filter(item => this.control.value.includes(this.getValue(item)));
    } else {
      this.selectedItems = this.items.filter(item => this.getValue(item) === this.control.value);
    }
  }
  updateDropdown(show) {
    if (this.dropdownOpen && !show) {
      this.dropdown.hide();
      this.dropdownOpen = false;
    } else {
      this.dropdown.show();
      this.dropdownOpen = true;
    }
  }
  getPlaceholder() {
    if (this.selectedItems.length > 0) {
      return "";
    }
    return this.placeholder;
  }
  updateDisplayedItems() {
    this.getItems().then(items => {
      this.displayedItems = items;
    });
  }
  static ɵfac = function MagienoBootstrapDropdownComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || MagienoBootstrapDropdownComponent)(i0.ɵɵdirectiveInject(PLATFORM_ID));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: MagienoBootstrapDropdownComponent,
    selectors: [["magieno-bootstrap-dropdown"]],
    viewQuery: function MagienoBootstrapDropdownComponent_Query(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵviewQuery(_c0, 5);
        i0.ɵɵviewQuery(_c1, 5);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.dropdownMenuElement = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.inputElement = _t.first);
      }
    },
    inputs: {
      control: "control",
      itemsProvider: "itemsProvider",
      labelKey: "labelKey",
      valueKey: "valueKey",
      items: "items",
      focused: "focused",
      multiple: "multiple",
      placeholder: "placeholder",
      name: "name",
      showChevronDown: "showChevronDown"
    },
    outputs: {
      itemSelect: "itemSelect",
      itemDeselect: "itemDeselect",
      click: "click"
    },
    ngContentSelectors: _c3,
    decls: 11,
    vars: 8,
    consts: [["inputElement", ""], ["dropdownMenu", ""], ["data-bs-toggle", "dropdown", 1, "dropdown", "form-control", "p-0", "d-flex", "align-items-center", "gap-2", "search-dropdown", "text-nowrap", 2, "height", "2.35rem", 3, "click"], [1, "d-flex", "h-75", "gap-1", "ps-2"], [1, "h-75", "ps-2", 3, "ps-2", "ps-3"], ["type", "text", "role", "button", 1, "dropdown-toggle", "p-0", "border-0", 2, "background", "none", "width", "inherit", "height", "inherit", 3, "focus", "blur", "keyup", "placeholder", "name", "formControl"], [1, "dropdown-menu"], [1, "me-2"], ["type", "button", 1, "btn", "btn-light", "btn-sm", "pt-0", "pb-0", "d-flex", "ps-0", "gap-1", "align-items-center"], ["type", "button", 1, "btn", "btn-light", "btn-sm", "pt-0", "pb-0", "d-flex", "ps-0", "gap-1", "align-items-center", 3, "click"], [1, "bi", "bi-x", "ps-1"], [1, "h-75", "ps-2"], ["href", "#", 1, "dropdown-item", 3, "click"], [1, "dropdown-item"], [1, "bi", "bi-chevron-down"]],
    template: function MagienoBootstrapDropdownComponent_Template(rf, ctx) {
      if (rf & 1) {
        const _r1 = i0.ɵɵgetCurrentView();
        i0.ɵɵprojectionDef(_c2);
        i0.ɵɵelementStart(0, "div", 2);
        i0.ɵɵlistener("click", function MagienoBootstrapDropdownComponent_Template_div_click_0_listener() {
          i0.ɵɵrestoreView(_r1);
          return i0.ɵɵresetView(ctx.clicked());
        });
        i0.ɵɵtemplate(1, MagienoBootstrapDropdownComponent_Conditional_1_Template, 3, 0, "div", 3)(2, MagienoBootstrapDropdownComponent_Conditional_2_Template, 2, 5, "div", 4);
        i0.ɵɵelementStart(3, "input", 5, 0);
        i0.ɵɵlistener("focus", function MagienoBootstrapDropdownComponent_Template_input_focus_3_listener() {
          i0.ɵɵrestoreView(_r1);
          return i0.ɵɵresetView(ctx.focused = true);
        })("blur", function MagienoBootstrapDropdownComponent_Template_input_blur_3_listener() {
          i0.ɵɵrestoreView(_r1);
          return i0.ɵɵresetView(ctx.focused = false);
        })("keyup", function MagienoBootstrapDropdownComponent_Template_input_keyup_3_listener($event) {
          i0.ɵɵrestoreView(_r1);
          return i0.ɵɵresetView(ctx.keyUp($event));
        });
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(5, "ul", 6, 1);
        i0.ɵɵrepeaterCreate(7, MagienoBootstrapDropdownComponent_For_8_Template, 3, 3, "li", null, i0.ɵɵrepeaterTrackByIndex);
        i0.ɵɵtemplate(9, MagienoBootstrapDropdownComponent_Conditional_9_Template, 3, 0, "li");
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(10, MagienoBootstrapDropdownComponent_Conditional_10_Template, 2, 0, "div", 7);
        i0.ɵɵelementEnd();
      }
      if (rf & 2) {
        i0.ɵɵclassProp("focus", ctx.focused);
        i0.ɵɵadvance();
        i0.ɵɵconditional(ctx.multiple ? 1 : 2);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("placeholder", ctx.getPlaceholder())("name", ctx.name)("formControl", ctx.searchControl);
        i0.ɵɵadvance(4);
        i0.ɵɵrepeater(ctx.displayedItems);
        i0.ɵɵadvance(2);
        i0.ɵɵconditional(ctx.displayedItems.length === 0 ? 9 : -1);
        i0.ɵɵadvance();
        i0.ɵɵconditional(ctx.showChevronDown ? 10 : -1);
      }
    },
    dependencies: [ReactiveFormsModule, i1.DefaultValueAccessor, i1.NgControlStatus, i1.FormControlDirective],
    styles: ["input[_ngcontent-%COMP%]:focus-visible, input[_ngcontent-%COMP%]:focus{outline:none}.search-dropdown[_ngcontent-%COMP%]{background-color:#e9ecef;border:none}.search-dropdown[_ngcontent-%COMP%]:hover{background-color:#dee2e6;cursor:pointer}.focus[_ngcontent-%COMP%]{color:var(--bs-body-color);background-color:var(--bs-body-bg)!important;border-color:#86b7fe;outline:0;box-shadow:0 0 0 .25rem #0d6efd40}"]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MagienoBootstrapDropdownComponent, [{
    type: Component,
    args: [{
      selector: 'magieno-bootstrap-dropdown',
      imports: [ReactiveFormsModule],
      template: "<div class=\"dropdown form-control p-0 d-flex align-items-center gap-2 search-dropdown  text-nowrap\"\n     data-bs-toggle=\"dropdown\" [class.focus]=\"focused\" style=\"height: 2.35rem;\" (click)=\"clicked()\">\n  @if (multiple) {\n    <div class=\"d-flex h-75 gap-1 ps-2\">\n      @for (item of this.selectedItems; track $index) {\n        <button type=\"button\" class=\"btn btn-light btn-sm pt-0 pb-0 d-flex ps-0 gap-1 align-items-center\"\n                (click)=\"selectedItemClicked(item)\"><i class=\"bi bi-x ps-1\"></i> <span>{{ this.getLabel(item) }}</span>\n        </button>\n      }\n    </div>\n  } @else {\n    <div class=\"h-75 ps-2\" [class.ps-2]=\"this.selectedItem === null\"\n         [class.ps-3]=\"this.selectedItem !== null\">{{ this.getLabel(this.selectedItem) }}\n    </div>\n  }\n\n  <input type=\"text\"\n         #inputElement\n         class=\"dropdown-toggle p-0 border-0\" style=\"background:none; width: inherit; height: inherit\" role=\"button\"\n         [placeholder]=\"getPlaceholder()\"\n         [name]=\"name\"\n         [formControl]=\"searchControl\"\n         (focus)=\"focused = true\"\n         (blur)=\"focused = false\"\n         (keyup)=\"keyUp($event)\"\n  />\n\n  <ul class=\"dropdown-menu\" #dropdownMenu>\n    @for (item of displayedItems; track $index) {\n      <li>\n        <button class=\"dropdown-item\"\n                [class.active]=\"$index === cursorPosition\"\n                (click)=\"itemSelected(item)\" href=\"#\">{{ this.getLabel(item) }}\n        </button>\n      </li>\n    }\n\n    @if (displayedItems.length === 0) {\n      <li>\n        <ng-content select=\"no-results\">\n          <button class=\"dropdown-item\">No results found.</button>\n        </ng-content>\n      </li>\n    }\n  </ul>\n\n  @if (showChevronDown) {\n    <div class=\"me-2\">\n      <i class=\"bi bi-chevron-down\"></i>\n    </div>\n  }\n</div>\n",
      styles: ["input:focus-visible,input:focus{outline:none}.search-dropdown{background-color:#e9ecef;border:none}.search-dropdown:hover{background-color:#dee2e6;cursor:pointer}.focus{color:var(--bs-body-color);background-color:var(--bs-body-bg)!important;border-color:#86b7fe;outline:0;box-shadow:0 0 0 .25rem #0d6efd40}\n"]
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [PLATFORM_ID]
    }]
  }], {
    control: [{
      type: Input
    }],
    itemsProvider: [{
      type: Input
    }],
    labelKey: [{
      type: Input
    }],
    valueKey: [{
      type: Input
    }],
    items: [{
      type: Input
    }],
    itemSelect: [{
      type: Output
    }],
    itemDeselect: [{
      type: Output
    }],
    dropdownMenuElement: [{
      type: ViewChild,
      args: ["dropdownMenu"]
    }],
    click: [{
      type: Output
    }],
    inputElement: [{
      type: ViewChild,
      args: ['inputElement']
    }],
    focused: [{
      type: Input
    }],
    multiple: [{
      type: Input
    }],
    placeholder: [{
      type: Input
    }],
    name: [{
      type: Input
    }],
    showChevronDown: [{
      type: Input
    }]
  });
})();

/*
 * Public API Surface of angular-dropdown
 */

/**
 * Generated bundle index. Do not edit.
 */

export { MagienoBootstrapDropdownComponent };
