<div class="accordion mt-4">
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button d-flex gap-2"
              type="button"
              data-bs-target="#collapseOutput" aria-expanded="false" aria-controls="collapseOutput"
              (click)="outputCollapsed = !outputCollapsed"
              [class.collapsed]="outputCollapsed"
              [class.bg-light-subtle]="status === TaskStatus.Idle"
              [class.bg-primary-subtle]="status === TaskStatus.Executing"
              [class.bg-success-subtle]="status === TaskStatus.Completed"
              [class.bg-danger-subtle]="status === TaskStatus.Error"
      >
        <i class="bi bi-terminal"></i>

        <div class="flex-grow-1">Output</div>

        <span class="badge"
              [class.text-bg-secondary]="status === TaskStatus.Idle"
              [class.text-bg-primary]="status === TaskStatus.Executing"
              [class.text-bg-success]="status === TaskStatus.Completed"
              [class.text-bg-danger]="status === TaskStatus.Error"
        >{{ status }}</span>
      </button>
    </h2>
    <div id="collapseOutput" class="accordion-collapse" [class.collapse]="outputCollapsed"
         data-bs-parent="#collapseOutput">
      <div class="accordion-body p-0">
        <div class="mt-1 p-3">


          @if (error) {
            <div class="alert alert-danger">{{ error }}</div>
          }

          <div>{{ this.output }}
            <button class="btn btn-link" *ngIf="this.output != ''" (click)="copyToClipboard(this.output)"><i
              class="bi bi-copy"></i></button>
          </div>

          @if (outputChunks.length > 0 && status !== TaskStatus.Idle) {

            <table class="table mt-3">
              <thead>
              <tr>
                <th>#</th>
                <th>Chunk</th>
              </tr>
              </thead>
              <tbody>

                @for (chunk of outputChunks; track $index) {
                  <tr>
                    <td>{{ $index }}</td>
                    <td>{{ chunk }}
                      <button class="btn btn-link" (click)="copyToClipboard(chunk)"><i class="bi bi-copy"></i></button>
                    </td>
                  </tr>
                }

              </tbody>
            </table>
          }

          <ng-content></ng-content>

          <!-- This below is deprecated, should use the footer direclty -->
          @if (this.executionPerformanceResult?.elapsedTime) {
            <div class="flex-fill">Elapsed time: <strong>{{ this.executionPerformanceResult?.elapsedTime }} ms</strong>
            </div>
          }

          @if (this.executionPerformanceResult?.firstResponseIn || this.executionPerformanceResult?.firstResponseNumberOfWords) {
            <div class="flex-fill">First Response:
              <strong>{{ this.executionPerformanceResult?.firstResponseIn ?? "N/A" }}</strong>
              ms, <strong>{{ this.executionPerformanceResult?.firstResponseNumberOfWords ?? "N/A" }} words</strong>
            </div>
          }

          @if (this.executionPerformanceResult?.totalExecutionTime || this.executionPerformanceResult?.totalNumberOfWords) {
            <div class="flex-fill">Total: <strong>{{ this.executionPerformanceResult?.totalExecutionTime ?? "N/A" }}
              ms</strong>,
              <strong>{{ this.executionPerformanceResult?.totalNumberOfWords ?? "N/A" }} words</strong></div>
          }

        </div>
        <div class="border-top border-1 p-3 bg-light m-0 d-flex gap-2 align-items-center">
          @if (status !== TaskStatus.Idle) {
            <div class="flex-grow-1 me-4">
              <h6 class="mt-0">Model download:</h6>
              <div class="progress" role="progressbar" aria-label="Basic example" aria-valuenow="0"
                   aria-valuemin="0" aria-valuemax="100">
                <div class="progress-bar" [style.width]="getDownloadProgress() + '%'">{{ getDownloadProgress() }}%</div>
              </div>
            </div>
          }
          @if (status === TaskStatus.Executing) {
            <button class="btn btn-danger btn-sm" (click)="abortExecutionHandler()">Abort execution</button>
            <button class="btn btn-danger btn-sm ms-2" (click)="abortExecutionFromCreateHandler()">Abort from 'create'
              signal
              execution
            </button>
          }
        </div>
      </div>

    </div>
  </div>
</div>


