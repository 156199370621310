<div class="container-fluid" xmlns="http://www.w3.org/1999/html">

  <app-page-title [title]="'Writer API'" [icon]="'bi-pencil-square'">
    <a class="btn btn-light"
       href="https://github.com/webmachinelearning/writing-assistance-apis?tab=readme-ov-file#writer-api"
       target="_blank"><i
      class="bi bi-file-text"></i> <span class="ms-2">Explainer</span></a>
    <a class="btn btn-light"
       href="https://github.com/webmachinelearning/writing-assistance-apis/blob/main/index.bs#L697" target="_blank"><i
      class="bi bi-body-text"></i> <span class="ms-2">Specifications</span></a>
  </app-page-title>


  <div class="row mb-3">
    <div class="col-12">

      <app-page-accordion
        [requirementsStatus]="this.apiFlag.status"
        (checkRequirementsEvent)="checkRequirements()"
        [requirements]="[this.getRequirement()]"
      >
        <div debugInformation>
          No debug information available.
        </div>
      </app-page-accordion>
    </div>
  </div>

  <h2 class="display-6 mt-5"><i class="bi bi-play"></i> Playground</h2>
  <hr>

  <app-output
    [status]="this.status"
    [error]="this.error"
    [downloadProgress]="this.loaded"
    [outputCollapsed]="outputCollapsed"
    [output]="this.output"
    (abortExecution)="abortTriggered()"
    (abortExecutionFromCreate)="abortFromCreateTriggered()"
  >

  </app-output>

  <div class="row mt-5">
    <div class="col-12 col-md-6">
      <h3 class="mt-3">Input:</h3>
      <textarea class="form-control" rows="8" [formControl]="inputFormControl"></textarea>

      <h3 class="mt-3">Context:</h3>
      <textarea class="form-control" rows="3" [formControl]="contextFormControl"></textarea>

    </div>
    <div class="col-12 col-md-6">


      <div class="row">
        <div class="col-4">
          <div class="mb-3">
            <label for="writer-tone" class="form-label">Tone</label>
            <app-search-select-dropdown id="writer-tone" [options]="WriterToneEnum | enumToSearchSelectDropdownOptions"
                                        [control]="toneFormControl"
                                        [name]="'writerTone'"></app-search-select-dropdown>
          </div>
        </div>
        <div class="col-4">
          <div class="mb-3">
            <label for="writer-format" class="form-label">Format</label>
            <app-search-select-dropdown id="writer-format"
                                        [options]="WriterFormatEnum | enumToSearchSelectDropdownOptions"
                                        [control]="formatFormControl"
                                        [name]="'writerFormat'"></app-search-select-dropdown>
          </div>
        </div>
        <div class="col-4">
          <div class="mb-3">
            <label for="writer-length" class="form-label">Length</label>
            <app-search-select-dropdown id="writer-length"
                                        [options]="WriterLengthEnum | enumToSearchSelectDropdownOptions"
                                        [control]="lengthFormControl"
                                        [name]="'writerLength'"></app-search-select-dropdown>
          </div>
        </div>

        <div class="col-6">
          <div class="mb-3">
            <label for="writer-expected-input-languages" class="form-label">Expected Input Languages</label>
            <app-search-select-multiple-dropdown id="writer-expected-input-languages"
                                                 [options]="LocaleEnum | enumToSearchSelectDropdownOptions"
                                                 [control]="expectedInputLanguagesFormControl"
                                                 [name]="'expectedInputLanguages'"></app-search-select-multiple-dropdown>
          </div>
        </div>
        <div class="col-6">
          <div class="mb-3">
            <label for="writer-expected-context-languages" class="form-label">Expected Context Languages</label>
            <app-search-select-multiple-dropdown id="writer-expected-context-languages"
                                                 [options]="LocaleEnum | enumToSearchSelectDropdownOptions"
                                                 [control]="expectedContextLanguagesFormControl"
                                                 [name]="'expectedContextLanguages'"></app-search-select-multiple-dropdown>
          </div>
        </div>
        <div class="col-6">
          <div class="mb-3">
            <label for="writer-output-language" class="form-label">Output Language</label>
            <app-search-select-dropdown id="writer-output-language"
                                        [options]="LocaleEnum | enumToSearchSelectDropdownOptions"
                                        [control]="outputLanguageFormControl"
                                        [name]="'outputLanguage'"></app-search-select-dropdown>
          </div>

        </div>
        <div class="col-12">
          <div class="mb-3">
            <label for="writer-context" class="form-label">Shared Context</label>
            <textarea class="form-control" id="writer-context" rows="3" [formControl]="sharedContextFormControl"></textarea>
          </div>

        </div>
      </div>
    </div>
    <div class="d-grid mt-3">
      <button class="btn btn-primary btn-lg" (click)="write()">Write</button>
    </div>
  </div>


  <h2 class="display-6 mt-5"><i class="bi bi-code-square"></i> Runnable code examples</h2>
  <hr>

  <div class="row">
    <div class="col-12 col-xl-6">
      <div class="card mt-3">
        <div class="card-header">
          <h4>Availability</h4>
        </div>
        <div class="card-body">
          <h5>Code</h5>
          <code-editor [code]="checkAvailabilityCode" [readonly]="true" [height]="'150px'"></code-editor>

          <h5 class="mt-3">Result</h5>
          <span class="code"
                [class.code-warning]="availabilityStatus === AvailabilityStatusEnum.AfterDownload || availabilityStatus === AvailabilityStatusEnum.Downloadable"
                [class.code-danger]="availabilityStatus === AvailabilityStatusEnum.No || availabilityStatus === AvailabilityStatusEnum.Unavailable"
                [class.code-success]="availabilityStatus === AvailabilityStatusEnum.Readily || availabilityStatus === AvailabilityStatusEnum.Available"
                [class.code-dark]="availabilityStatus === AvailabilityStatusEnum.Unknown"
          >{{ availabilityStatus }}</span>

          @if (availabilityError) {
            <div class="alert alert-danger m-0 mt-2 mb-2">{{ availabilityError }}</div>
          }

          <div class="row mt-5">
            <div class="col-12 d-grid">
              <button class="btn btn-outline-primary d-block" (click)="checkAvailability()">Check availability
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="col-12 col-xl-6">
      <div class="card mt-3">
        <div class="card-header d-flex">
          <h4 class="flex-grow-1 m-0">Execute</h4>

          <div class="form-check form-switch m-0 p-0">
            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"
                   [formControl]="useStreamingFormControl">
            <label class="form-check-label" for="flexSwitchCheckDefault">Streaming</label>
          </div>
        </div>
        <div class="card-body">
          <h5>Code</h5>
          <code-editor [code]="writeCode" [readonly]="true" [height]="'350px'"></code-editor>

          <div class="mt-3 mb-3 d-flex">
          </div>
          @if (status === TaskStatus.Error) {
            <div class="alert alert-danger m-0 mb-2">{{ outputStatusMessage }}</div>
          }

          <div class="row mt-2">
            <div class="col-12 d-grid">
              <button class="btn btn-primary d-block" (click)="write()">Write</button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>




