import * as i0 from '@angular/core';
import { EventEmitter, Component, Output, Input } from '@angular/core';
const _c0 = ["*"];
class MagienoDragAndDropComponent {
  hasEnteredDropZone = false;
  onDragEnter = new EventEmitter();
  onDragLeave = new EventEmitter();
  onDragOver = new EventEmitter();
  onDrop = new EventEmitter();
  onFileSystemHandlesDropped = new EventEmitter();
  onFilesDropped = new EventEmitter();
  onFileSystemEntriesDropped = new EventEmitter();
  showOpenFilePickerOptions;
  clickEvents;
  subscriptions = [];
  ngOnChanges(changes) {
    if (changes["clickEvents"] && this.clickEvents) {
      this.subscriptions.push(this.clickEvents.subscribe(() => this.openFilePicker()));
    }
  }
  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
  async onDropEvent(event) {
    if (!event.dataTransfer) {
      return;
    }
    if (event.dataTransfer.files) {
      const files = [];
      for (let i = 0; i < event.dataTransfer.files.length; i++) {
        const file = event.dataTransfer.files[i];
        files.push(file);
      }
      this.onFilesDropped.emit(files);
    }
    const supportsFileSystemAccessAPI = 'getAsFileSystemHandle' in DataTransferItem.prototype;
    const supportsWebkitGetAsEntry = 'webkitGetAsEntry' in DataTransferItem.prototype;
    if (event.dataTransfer.items) {
      // Retrieve the file system handles and push them back.
      let fileSystemHandleItems = [];
      let fileSystemEntryItems = [];
      if (supportsFileSystemAccessAPI) {
        // @ts-ignore
        fileSystemHandleItems = [...event.dataTransfer.items].map(item => item.getAsFileSystemHandle());
      }
      if (supportsWebkitGetAsEntry) {
        // @ts-ignore
        fileSystemEntryItems = [...event.dataTransfer.items].map(item => item.webkitGetAsEntry());
      }
      const fileSystemHandles = [];
      for await (const item of fileSystemHandleItems) {
        fileSystemHandles.push(item);
      }
      const fileSystemEntries = [];
      for await (const item of fileSystemEntryItems) {
        fileSystemEntries.push(item);
      }
      if (fileSystemEntries.length > 0) {
        this.onFileSystemEntriesDropped.emit(fileSystemEntries);
      }
      if (fileSystemHandles.length > 0) {
        this.onFileSystemHandlesDropped.emit(fileSystemHandles);
      }
    }
  }
  onDragEnterEvent(event) {
    this.hasEnteredDropZone = true;
    this.onDragEnter.emit(event);
  }
  onDragOverEvent(event) {
    this.onDragOver.emit(event);
  }
  onDragLeaveEvent(event) {
    this.hasEnteredDropZone = false;
    this.onDragLeave.emit(event);
  }
  async openFilePicker() {
    if ("showOpenFilePicker" in window) {
      // @ts-ignore
      const fileSystemFileHandles = await window.showOpenFilePicker(this.showOpenFilePickerOptions);
      this.onFileSystemHandlesDropped.emit(fileSystemFileHandles);
    }
  }
  click() {
    this.openFilePicker();
  }
  static ɵfac = function MagienoDragAndDropComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || MagienoDragAndDropComponent)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: MagienoDragAndDropComponent,
    selectors: [["magieno-drag-and-drop"]],
    inputs: {
      showOpenFilePickerOptions: "showOpenFilePickerOptions",
      clickEvents: "clickEvents"
    },
    outputs: {
      onDragEnter: "onDragEnter",
      onDragLeave: "onDragLeave",
      onDragOver: "onDragOver",
      onDrop: "onDrop",
      onFileSystemHandlesDropped: "onFileSystemHandlesDropped",
      onFilesDropped: "onFilesDropped",
      onFileSystemEntriesDropped: "onFileSystemEntriesDropped"
    },
    features: [i0.ɵɵNgOnChangesFeature],
    ngContentSelectors: _c0,
    decls: 2,
    vars: 2,
    consts: [[1, "dropzone", 3, "drop", "dragenter", "dragover", "dragleave", "click"]],
    template: function MagienoDragAndDropComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵlistener("drop", function MagienoDragAndDropComponent_Template_div_drop_0_listener($event) {
          ctx.onDropEvent($event);
          return $event.preventDefault();
        })("dragenter", function MagienoDragAndDropComponent_Template_div_dragenter_0_listener($event) {
          ctx.onDragEnterEvent($event);
          return $event.preventDefault();
        })("dragover", function MagienoDragAndDropComponent_Template_div_dragover_0_listener($event) {
          ctx.onDragOverEvent($event);
          return $event.preventDefault();
        })("dragleave", function MagienoDragAndDropComponent_Template_div_dragleave_0_listener($event) {
          ctx.onDragLeaveEvent($event);
          return $event.preventDefault();
        })("click", function MagienoDragAndDropComponent_Template_div_click_0_listener($event) {
          ctx.click();
          return $event.preventDefault();
        });
        i0.ɵɵprojection(1);
        i0.ɵɵelementEnd();
      }
      if (rf & 2) {
        i0.ɵɵclassProp("dragEntered", ctx.hasEnteredDropZone);
      }
    },
    styles: [".dropzone[_ngcontent-%COMP%]{border:2px dashed #e9ecef;border-radius:5px;padding:20px;text-align:center;cursor:pointer;transition:border .3s ease;height:100%!important;width:100%!important}.dropzone.dragEntered[_ngcontent-%COMP%]{border-color:#0d6efd}"]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MagienoDragAndDropComponent, [{
    type: Component,
    args: [{
      selector: 'magieno-drag-and-drop',
      imports: [],
      template: `
    <div class=" dropzone" [class.dragEntered]="hasEnteredDropZone"
         (drop)="onDropEvent($event); $event.preventDefault();"
         (dragenter)="onDragEnterEvent($event); $event.preventDefault();"
         (dragover)="onDragOverEvent($event); $event.preventDefault();"
         (dragleave)="onDragLeaveEvent($event); $event.preventDefault();"
         (click)="click(); $event.preventDefault();"
    >
      <ng-content></ng-content>
    </div>
  `,
      styles: [".dropzone{border:2px dashed #e9ecef;border-radius:5px;padding:20px;text-align:center;cursor:pointer;transition:border .3s ease;height:100%!important;width:100%!important}.dropzone.dragEntered{border-color:#0d6efd}\n"]
    }]
  }], null, {
    onDragEnter: [{
      type: Output
    }],
    onDragLeave: [{
      type: Output
    }],
    onDragOver: [{
      type: Output
    }],
    onDrop: [{
      type: Output
    }],
    onFileSystemHandlesDropped: [{
      type: Output
    }],
    onFilesDropped: [{
      type: Output
    }],
    onFileSystemEntriesDropped: [{
      type: Output
    }],
    showOpenFilePickerOptions: [{
      type: Input
    }],
    clickEvents: [{
      type: Input
    }]
  });
})();

/*
 * Public API Surface of angular-drag-and-drop
 */

/**
 * Generated bundle index. Do not edit.
 */

export { MagienoDragAndDropComponent };
