<div class="container" xmlns="http://www.w3.org/1999/html">

  <app-page-title [title]="'Home'" [icon]="'bi-house'">

  </app-page-title>

  <div class="row mt-4">
    <div class="col-12">
      <p>This built-in AI playground presents all the AI features directly available in Chrome as JS APIs:</p>

      <ul class="list-unstyled mt-4">
        <li><a class="btn btn-light" routerLink="translator-api"><i class="bi bi-translate"></i> Translator API</a></li>
        <li class="mt-3"><a class="btn btn-light" routerLink="language-detector-api"><i class="bi bi-globe2"></i>
          Language Detector API</a></li>
        <li class="mt-3"><a class="btn btn-light" routerLink="summarizer-api"><i class="bi bi-text-paragraph"></i>
          Summarizer API</a></li>
        <li class="mt-3"><a class="btn btn-light" routerLink="writer-api"><i class="bi bi-pencil-square"></i> Writer API</a>
        </li>
        <li class="mt-3"><a class="btn btn-light" routerLink="rewriter-api"><i class="bi bi-arrow-clockwise"></i>
          Rewriter API</a></li>
        <li class="mt-3"><a class="btn btn-light" routerLink="prompt-api"><i class="bi bi-input-cursor-text"></i> Prompt
          API</a></li>
      </ul>
    </div>
  </div>

  <h2 class="display-6 mt-3">Questions?</h2>

  <p class="mt-3">Contact me, <a href="https://etiennenoel.com">Etienne Noël</a></p>
  <div>
    <a class="btn btn-light btn-lg" href="https://x.com/etiennenoel"><i class="bi bi-twitter-x"></i></a>
    <a class="btn btn-light btn-lg ms-2" href="https://www.linkedin.com/in/enoel19/"><i class="bi bi-linkedin"></i></a>
  </div>
</div>
