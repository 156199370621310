<div class="row">
  <div class="col-3">
    <label>Role</label>
    <app-search-select-dropdown [options]="roles"
                                [control]="roleFormControl"
    ></app-search-select-dropdown>
  </div>
  <div class="col-9">
    <div class="form-group">
      <label for="prompt" class="form-label">Prompt</label>
      <textarea class="form-control" rows="5" id="prompt" placeholder="Enter your prompt here"
                [formControl]="promptFormControl"></textarea>
    </div>
  </div>
</div>

