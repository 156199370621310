@if (status) {
  @switch (status) {
    @case (StepStatus.Idle) {
      <div class="text-body-tertiary"><i class="bi bi-three-dots"></i></div>
    }
    @case (StepStatus.Error) {
      <div class="text-danger"><i class="bi bi-exclamation-circle"></i></div>
    }
    @case (StepStatus.Executing) {
      <div class="text-primary">
        <div class="spinner-grow" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    }
    @case (StepStatus.Completed) {
      <div class="text-success"><i class="bi bi-check-circle"></i></div>
    }
  }
}
