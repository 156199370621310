<div class="container-fluid">

  <app-page-title [title]="'Prompt API'" [icon]="'bi-input-cursor-text'">
    <a class="btn btn-light" href="https://github.com/webmachinelearning/prompt-api" target="_blank"><i
      class="bi bi-file-text"></i> <span class="ms-2">Explainer</span></a>
    <a class="btn btn-light disabled"
       href=""
       target="_blank"><i class="bi bi-body-text"></i> <span class="ms-2">Specifications (Not yet available)</span></a>
  </app-page-title>

  <div class="row mb-3">
    <div class="col-12">

      <app-page-accordion
        [requirementsStatus]="this.apiFlag.status"
        (checkRequirementsEvent)="checkRequirements()"
        [requirements]="[this.apiFlag]"
      >
        <div debugInformation>
          No debug information available yet.
        </div>
      </app-page-accordion>
    </div>
  </div>

  <div class="row mt-5">
    <div class="col-12">
      <h3>Options</h3>

      <div class="row">
        <div class="col-6">
          <div class="row">
            <div class="col-6">
              <div class="mb-3">
                <label for="topK" class="form-label">Top K</label>
                <input type="number" class="form-control" id="topK" [formControl]="topKFormControl">
              </div>
            </div>
            <div class="col-6">
              <div class="mb-3">
                <label for="temperature" class="form-label">Temperature</label>
                <input type="number" class="form-control" id="temperature" [formControl]="temperatureFormControl">
              </div>
            </div>
            <div class="col-12">
              <div class="mb-3">
                <label for="summarizer-expected-input-languages" class="form-label">Expected Input Languages</label>
                <app-search-select-multiple-dropdown id="summarizer-expected-input-languages"
                                                     [options]="LocaleEnum | enumToSearchSelectDropdownOptions"
                                                     [control]="expectedInputLanguagesFormControl"
                                                     [name]="'expectedInputLanguages'"></app-search-select-multiple-dropdown>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label for="system-prompt" class="form-label">System Prompt</label>
              <textarea class="form-control" rows="5" id="system-prompt" placeholder="Enter your content here"
                        [formControl]="systemPromptFormControl"></textarea>
            </div>
          </div>
        </div>

        <div class="col-6">
          <h4>Initial prompts</h4>
          @for (prompt of initialPrompts; track $index) {
            <div class="card mb-3">
              <div class="card-body">
                <app-prompt [prompt]="prompt"
                            (promptChange)="onInitialPromptsChange($event, $index)"
                            [roles]="PromptInitialRoleEnum | enumToSearchSelectDropdownOptions"></app-prompt>

                <button class="btn btn-danger btn-sm" (click)="deleteInitialPrompt($index)">Delete
                </button>
              </div>
            </div>
          }

          <button class="btn btn-outline-primary" (click)="appendInitialPrompt()">Add Initial content</button>
        </div>

      </div>
    </div>
  </div>

  <div class="row mt-5">
    <div class="col-12 col-md-6">
      <h3>Prompt(s)</h3>

      <div class="row mb-5">
        <div class="col-6">
          <div class="form-group">
            <label>Type of content</label>
            <app-search-select-dropdown
              [options]="PromptTypeEnum | enumToSearchSelectDropdownOptions"
              [control]="promptTypeFormControl"
            ></app-search-select-dropdown>
          </div>
        </div>
      </div>

      @switch (promptTypeFormControl.value) {
        @case (PromptTypeEnum.SequenceAILanguageModelPrompt) {

          @for (prompt of prompts; track $index) {
            <div class="card mb-3 mt-4">
              <div class="card-body">
                <app-prompt [prompt]="prompt"
                            (promptChange)="onPromptsChange($event, $index)"
                            [roles]="PromptRoleEnum | enumToSearchSelectDropdownOptions"></app-prompt>

                <button class="btn btn-danger btn-sm" (click)="deletePrompt($index)">Delete</button>
              </div>
            </div>
          }

          <button class="btn btn-outline-primary mt-3" (click)="appendPrompt()">Add content</button>
        }
        @case (PromptTypeEnum.String) {
          <div class="form-group">
            <label for="prompt" class="form-label">Prompt</label>
            <textarea id="prompt" class="form-control" [formControl]="stringPromptFormControl"></textarea>
          </div>
        }
        @case (PromptTypeEnum.AILanguageModelPrompt) {
          <div class="card mb-3 mt-4">
            <div class="card-body">
              <div class="form-group">
                <app-prompt
                  [prompt]="this.prompt"
                  (promptChange)="onPromptChange($event)"
                  [roles]="PromptRoleEnum | enumToSearchSelectDropdownOptions"></app-prompt>
              </div>
            </div>
          </div>
        }
      }


    </div>
  </div>

  <div class="row mt-5">
    <div class="col-12 col-md-6">
      <h3>Medias</h3>

      <div cdkDropList (cdkDropListDropped)="drop($event)">
        @for (media of medias; track $index) {
          <div class="card d-flex flex-row align-items-center gap-3 p-3 mb-3" style="height: 150px;" cdkDrag>
            <button class="btn"><i class="bi bi-grip-vertical"></i></button>


            @if (media.type === 'image') {
              <div class="p-2">
                <img class="img-fluid" [src]="getImageSrc(media)"/></div>
              <div class="flex-grow-1">
                <h6 class="m-0 p-0 mt-2 ms-2">{{ media.title }}</h6>
              </div>
            } @else if (media.type === 'audio') {
              <div class="flex-grow-1">
                <audio [src]="getAudioSrc(media)" controls></audio>
                <h6 class="m-0 p-0 mt-2 ms-2">{{ media.title }}</h6>
              </div>
            }


            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"
                     [(ngModel)]="media.includeInPrompt">
              <label class="form-check-label" for="flexSwitchCheckDefault">Pass to content?</label>
            </div>

            <div>
              <button class="btn btn-danger btn-sm" (click)="deleteMedia($index)">Delete</button>
            </div>
          </div>
        }
      </div>

      <magieno-drag-and-drop (onFileSystemHandlesDropped)="onFileSystemHandlesDropped($event)">
        <div class="d-flex flex-column h-100 w-100">
          <div class="align-content-center flex-fill">

            Click or drag and drop an <strong><i class="bi bi-music-note"></i>audio</strong> file or <strong> <i
            class="bi bi-images "></i> image</strong> here
          </div>
        </div>
      </magieno-drag-and-drop>
    </div>
  </div>

  @if (error) {
    <div class="alert alert-danger alert-dismissible mt-5">
      <div>{{ this.error }}</div>
      <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    </div>
  }


  <div class="row mt-2">
    <div class="col-12 col-md-6">
      <div class="card mt-3">
        <div class="card-header">
          <h4 class="m-0">Availability</h4>
        </div>
        <div class="card-body">
          <h5>Code</h5>
          <code-editor [code]="checkAvailabilityCode" [readonly]="true" [height]="'100px'"></code-editor>

          <h5 class="mt-3">Result</h5>
          <span class="code"
                [class.code-warning]="availabilityStatus === AvailabilityStatusEnum.AfterDownload || availabilityStatus === AvailabilityStatusEnum.Downloadable"
                [class.code-danger]="availabilityStatus === AvailabilityStatusEnum.No || availabilityStatus === AvailabilityStatusEnum.Unavailable"
                [class.code-success]="availabilityStatus === AvailabilityStatusEnum.Readily || availabilityStatus === AvailabilityStatusEnum.Available"
                [class.code-dark]="availabilityStatus === AvailabilityStatusEnum.Unknown"
          >{{ availabilityStatus }}</span>

          <div class="row mt-5">
            <div class="col-12 d-grid">
              <button class="btn btn-outline-primary d-block" (click)="checkAvailability()">Check availability</button>
            </div>
          </div>

        </div>
      </div>

    </div>
    <div class="col-12 col-md-6">
      <div class="card mt-3">
        <div class="card-header">
          <h4 class="m-0">Parameters</h4>
        </div>
        <div class="card-body">
          <h5>Code</h5>
          <code-editor [code]="paramsCode" [readonly]="true" [height]="'50px'"></code-editor>

          <table class="table">
            <tbody>
            <tr>
              <th>Default TopK</th>
              <td>{{ this.params?.defaultTopK ?? "N/A" }}</td>
            </tr>
            <tr>
              <th>Max TopK</th>
              <td>{{ this.params?.maxTopK ?? "N/A" }}</td>
            </tr>
            <tr>
              <th>Default Temperature</th>
              <td>{{ this.params?.defaultTemperature ?? "N/A" }}</td>
            </tr>
            <tr>
              <th>Max Temperature</th>
              <td>{{ this.params?.maxTemperature ?? "N/A" }}</td>
            </tr>
            </tbody>
          </table>

          <div class="row mt-5">
            <div class="col-12 d-grid">
              <button class="btn btn-outline-primary d-block" (click)="getParams()">Get parameters</button>
            </div>
          </div>

        </div>
      </div>

    </div>
    <div class="col-12 col-md-6">
      <app-output
        [status]="this.status"
        [output]="output ?? ''"

      ></app-output>
    </div>
    <div class="col-12 col-md-6">
      <div class="card mt-3">
        <div class="card-header d-flex align-items-center">
          <h4 class="flex-grow-1 m-0">Execute</h4>
          <div class="form-check form-switch m-0 p-0">
            <input class="form-check-input" type="checkbox" role="switch" id="isStreaming"
            >
            <label class="form-check-label" for="isStreaming">Streaming</label>
          </div>
        </div>
        <div class="card-body">
          <h5>Code</h5>
          <code-editor [code]="executeCode" [readonly]="true" [height]="'350px'"></code-editor>


          <div class="row mt-5">
            <div class="col-12 d-grid">
              <button class="btn btn-outline-primary d-block" (click)="execute()">Execute</button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</div>
