<div class="card">
  <div class="card-body d-flex">
    <div class="flex-grow-1"><ng-content></ng-content></div>

    @if (status === RequirementStatus.Pending) {
      <div class="text-primary">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        {{ message }}
      </div>
    } @else if (status === RequirementStatus.Pass) {
      <div class="text-success">
        <i class="bi bi-check-circle-fill text-success"></i>
        {{ message }}
      </div>
    } @else if (status === RequirementStatus.Fail) {
      <div class="text-danger">
        <i class="bi bi-x-circle-fill"></i>
        {{ message }}
      </div>
    }
  </div>
</div>
