<h2
  class="d-flex"
  [class.text-body-tertiary]="status === StepStatus.Idle"
  [class.text-danger]="status === StepStatus.Error"
  [class.text-primary]="status === StepStatus.Executing"
  [class.text-success]="status === StepStatus.Completed"
>
  <app-step-status-icon class="me-2" [status]="status"></app-step-status-icon>
  {{ title }}
</h2>
