<div class="container-fluid" xmlns="http://www.w3.org/1999/html">

  <app-page-title [title]="'Language Detector API'" [icon]="'bi-globe2'">
    <a class="btn btn-light" href="https://github.com/webmachinelearning/translation-api?tab=readme-ov-file#language-detection" target="_blank"><i
      class="bi bi-file-text"></i> <span class="ms-2">Explainer</span></a>
    <a class="btn btn-light" href="https://github.com/webmachinelearning/translation-api/pull/38/files#diff-5e793325cd2bfc452e268a4aa2f02b4024dd9584bd1db3c2595f61f1ecf7b985" target="_blank"><i class="bi bi-body-text"></i> <span class="ms-2">Specifications</span></a>
  </app-page-title>

  <div class="row mb-3">
    <div class="col-12">

      <app-page-accordion
        [requirementsStatus]="this.apiFlag.status"
        (checkRequirementsEvent)="checkRequirements()"
        [requirements]="getRequirements()"
      >
        <div debugInformation>
          <p>To manually install and uninstall language packs: <span class="code">chrome://on-device-translation-internals/</span>
          </p>
          <p class="mt-1">Models are <strong>located</strong> in: (MacOS) <span class="code">/Users/$&#123;username&#125;/Library/Application Support/Google/Chrome/TranslateKit/models/</span>
          </p>
          <p class="mt-1">Models are <strong>cached</strong> in: (MacOS) <span class="code">/Users/$&#123;username&#125;/Library/Caches/Google/Chrome/Default/Cache/Cache_Data/</span>
          </p>
        </div>
      </app-page-accordion>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-12">
      <h2 class="display-6 mt-5"><i class="bi bi-play"></i> Playground</h2>
      <hr>

      <app-output
        [status]="this.detectionStatus"
        [error]="this.error"
        [downloadProgress]="this.loaded"
        [outputCollapsed]="outputCollapsed"
        [output]="this.output"
        (abortExecution)="abortTriggered()"
        (abortExecutionFromCreate)="abortFromCreateTriggered()"
      >
        @if (results.length > 0) {
          <div style="height: 500px; overflow: auto">
            <table class="table table-responsive">
              <thead>
              <tr>
                <th>Language</th>
                <th>Confidence</th>
                <th>Confidence (Raw)</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let item of results">
                <td>{{ item.detectedLanguage }}</td>
                <td>{{ item.confidence | percent }}</td>
                <td>{{ item.confidence }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        }
      </app-output>

      <div class="row mt-4">
        <div class="col-12">

          <label for="input" class="form-label">Input</label>
          <textarea id="input" class="form-control" placeholder="Enter here the text you want to detect the language of." rows="8" [formControl]="inputFormControl"></textarea>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-6">
          <div class="mb-3">
            <label for="expected-input-languages" class="form-label">Expected Input Languages</label>
            <app-search-select-multiple-dropdown id="expected-input-languages"
                                                 [options]="LocaleEnum | enumToSearchSelectDropdownOptions"
                                                 [control]="expectedInputLanguagesFormControl"
                                                 [name]="'expectedInputLanguages'"></app-search-select-multiple-dropdown>
          </div>
        </div>
      </div>

      <div class="d-grid">
        <button class="btn btn-primary btn-lg mt-4" (click)="detect()">Detect</button>
      </div>
    </div>
    <div class="col-12 col-md-6">

    </div>
  </div>

<h2 class="display-6 mt-5"><i class="bi bi-code-square"></i> Runnable code examples</h2>
  <hr>
  <div class="row mt-4">
    <div class="col-12 col-xl-6">
      <app-card [status]="availabilityTaskStatus">
        <div header><h3 class="m-0">Availability</h3></div>
        <div class="card-body">
          <h5>Code</h5>
          <code-editor [code]="availabilityCode" [readonly]="true" [height]="'100px'"></code-editor>

          <h5 class="mt-3">Result</h5>
          <span class="code"
                [class.code-warning]="availabilityStatus === AvailabilityStatusEnum.AfterDownload || availabilityStatus === AvailabilityStatusEnum.Downloadable"
                [class.code-danger]="availabilityStatus === AvailabilityStatusEnum.No || availabilityStatus === AvailabilityStatusEnum.Unavailable"
                [class.code-success]="availabilityStatus === AvailabilityStatusEnum.Readily || availabilityStatus === AvailabilityStatusEnum.Available"
                [class.code-dark]="availabilityStatus === AvailabilityStatusEnum.Unknown"
          >{{ availabilityStatus }}</span>

          <div class="row mt-5">
            <div class="col-12 d-grid">
              <button class="btn btn-outline-primary d-block" (click)="checkAvailability()">Check availability</button>
            </div>
          </div>
        </div>
      </app-card>
    </div>


    <div class="col-12 col-xl-6">
      <app-card [status]="detectionStatus">
        <div header><h3 class="m-0">Detect language</h3></div>
        <div class="card-body">
          <h5>Code</h5>
          <code-editor [code]="detectCode" [readonly]="true" [height]="'250px'"></code-editor>

          <div class="row mt-5">
            <div class="col-12 d-grid">
              <button class="btn btn-primary d-block" (click)="detect()">Detect</button>
            </div>
          </div>
        </div>
      </app-card>
    </div>
  </div>


</div>
