<div class="container-fluid" xmlns="http://www.w3.org/1999/html">

  <app-page-title [title]="'Audio Prompt API'" [icon]="'bi-volume-up'">
    <p class="text-muted">This page contains examples using the Audio Prompt API.</p>
  </app-page-title>


  <div class="row mb-3">
    <div class="col-12">

      <app-page-accordion
        [requirementsStatus]="this.apiFlag.status"
        (checkRequirementsEvent)="checkRequirements()"
        [requirements]="[this.apiFlag]"
      >
        <div debugInformation>
          <h5>Activate all the flags</h5>

          <table class="table">
            <thead>
            <tr>
              <th>Flag</th>
              <th>Value to select</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td><span class="code">chrome://flags/#optimization-guide-on-device-model</span></td>
              <td><span class="code code-info">Enabled Bypass*</span></td>
            </tr>
            <tr>
              <td><span class="code">chrome://flags/#prompt-api-for-gemini-nano-multimodal-input</span></td>
              <td><span class="code code-info">Enabled</span></td>
            </tr>
            <tr>
              <td><span class="code">chrome://flags/#optimization-guide-debug-logs</span></td>
              <td><span class="code code-info">Enabled</span></td>
            </tr>
            </tbody>
          </table>

          <h5 class="mt-4">Verify that Gemini Nano has been downloaded</h5>
          <ol style="line-height: 2;">
            <li>Navigate to <span class="code">chrome://components</span></li>
            <li>Search for <span class="code">Optimization Guide On Device Model</span> on the page.</li>
            <li>Click on <span class="code">Check for update</span></li>
            <li>Ensure that it says <span class="code code-info">Up-to-date</span></li>
          </ol>
        </div>

        <div faq>
          <h5>Audio is limited to 30 secondes</h5>
          <p>Audio support right now is limited to 30 seconds. However, see the <a href="#long-audio">Long Audio</a>
            section for how to circumvent this limitations.</p>

          <h5>It seems like the page is stuck</h5>
          <p>After you have clicked on Execute for the first time, Chrome has to download Gemini Nano. This can take a
            while (5-10 minutes). While this is happening, the page can seem frozen or like nothing is happening. Give
            it time then try to restart your browser.</p>
        </div>
      </app-page-accordion>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-12">
      @defer () {
        <div class="d-flex mt-5">
          <button class="btn" (click)="audioFromUrlCollapsed = !audioFromUrlCollapsed">
            @if (audioFromUrlCollapsed) {
              <div>
                <i class="bi bi-caret-right-fill"></i>
              </div>
            } @else {
              <div>
                <i class="bi bi-caret-down-fill"></i>
              </div>
            }
          </button>
          <h2 class="display-6 flex-grow-1" id="audio_prompt_from_url"><i class="bi bi-globe"></i> From url</h2>
        </div>
        <hr>

        @if (!audioFromUrlCollapsed) {
          <app-audio-prompt-from-url></app-audio-prompt-from-url>
        }
      }
    </div>
  </div>


  <div class="row mt-4">
    <div class="col-12">
      @defer () {
        <div class="d-flex mt-5">
          <button class="btn" (click)="audioFromFilesCollapsed = !audioFromFilesCollapsed">
            @if (audioFromFilesCollapsed) {
              <div>
                <i class="bi bi-caret-right-fill"></i>
              </div>
            } @else {
              <div>
                <i class="bi bi-caret-down-fill"></i>
              </div>
            }
          </button>
          <h2 class="display-6 flex-grow-1"><i class="bi bi-file-earmark-music"></i> Audio files</h2>
        </div>
        <hr>
        @if (!audioFromFilesCollapsed) {
          <app-audio-prompt-from-file></app-audio-prompt-from-file>
        }
      }
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-12">
      @defer () {
        <div class="d-flex mt-5">
          <button class="btn" (click)="audioFromMicrophoneCollapsed = !audioFromMicrophoneCollapsed">
            @if (audioFromMicrophoneCollapsed) {
              <div>
                <i class="bi bi-caret-right-fill"></i>
              </div>
            } @else {
              <div>
                <i class="bi bi-caret-down-fill"></i>
              </div>
            }
          </button>
          <h2 class="display-6 flex-grow-1"><i class="bi bi-mic"></i> From Microphone</h2>
        </div>
        <hr>

        @if (!audioFromMicrophoneCollapsed) {
          <app-audio-prompt-from-microphone></app-audio-prompt-from-microphone>
        }
      }

    </div>
  </div>
</div>
