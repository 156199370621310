<div class="row mt-5">
  <div class="col-12">
    <h1 class="display-5"><i
      class="bi" [className]="icon"></i> <span class="ms-3">{{ title }}</span></h1>

    <div class="mt-4 d-flex gap-4 align-items-center">
      <ng-content></ng-content>
    </div>
    <hr>
  </div>
</div>
